<template>
  <div class="absolute top-4 right-4">
    <NotificationToast
      v-for="toast in toasts"
      :key="toast.id"
      :type="toast.type"
      :to="toast.to"
      @close="removeToast(toast.id!)"
    >
      <h4>{{ toast.title }}</h4>
      <p>{{ toast.message }}</p>
    </NotificationToast>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import { useToastStore } from '~/store/toast';

const store = useToastStore();

const { toasts } = storeToRefs(store);

function removeToast(id: string) {
  store.removeToast(id);
}
</script>
